<template>
  <div>
    <h1 class="headline">Schickt uns eure Erinnerungen!</h1>
    <p class="paragraph">Bei erfolgreichem Upload werdet ihr mit Konfetti belohnt! Solltet ihr kein Konfetti sehen, dann ist leider etwas schief gelaufen. Schickt eure Schnappschüsse dann einfach gerne per WhatsApp an uns.</p>
    <div class="dropzone">
      <input type="file" name="file" class="dropzone__input" multiple @change="onFileChange"/>
      <div class="dropzone__overlay">
        <div class="dropzone__text">
          <p>Hier klicken</p>
          <p>oder</p>
          <p>Dateien hier ablegen</p>
        </div>
      </div>
    </div>
    <ul class="filelist">
      <li v-for="(file, index) in selectedFiles" :key="index" class="filelist__item">
        <div class="filelist__filename">{{ file.name }}</div>
        <div v-if="progress[index] !== undefined" class="filelist__progress">
          <div class="filelist__percentage">{{ progress[index] }}%</div>
          <div class="filelist__bar">
            <div class="filelist__inner" :style="{ width: progress[index] + '%'}"></div>
          </div>
        </div>
      </li>
    </ul>
    <button @click="uploadFiles" v-if="selectedFiles.length" :disabled="submitted" class="submit">
      <span v-if="!submitted">Upload starten</span>
      <span v-if="submitted">Upload läuft</span>
      <span v-if="submitted && success">Upload erfolgreich</span>
      <span v-if="submitted && error">Upload fehlgeschlagen</span>
    </button>

    <button @click="reloadUploader" v-if="done" class="reload">Neu laden um weitere Bilder hochzuladen</button>
  </div>
</template>

<script setup>
import {ref} from 'vue';
import axios from 'axios';
import confetti from 'canvas-confetti';

const selectedFiles = ref([]);
const progress = ref([]);
const submitted = ref(false);
const success = ref(false);
const error = ref(false);
const done = ref(false);

const onFileChange = (event) => {
  selectedFiles.value = Array.from(event.target.files);
  progress.value = new Array(selectedFiles.value.length).fill(0);
};

const uploadFiles = async () => {
  submitted.value = true;

  if (selectedFiles.value.length === 0) {
    alert("Please select files to upload.");
    return;
  }

  const formDataArray = [];
  selectedFiles.value.forEach((file, index) => {
    const formData = new FormData();
    formData.append("file", file);
    formDataArray.push({index, formData});
  });

  const uploadPromises = formDataArray.map(({index, formData}) => {
    return axios.post("/uploads", formData, {
      onUploadProgress: progressEvent => {
        progress.value[index] = Math.round((progressEvent.loaded / progressEvent.total) * 100);
      }
    });
  });

  try {
    await Promise.all(uploadPromises);
    // alert("Files uploaded successfully.");
    selectedFiles.value = [];
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 }
    });
    success.value = true;
  } catch (error) {
    console.error("Error uploading files:", error);
    // alert("Error uploading files.");
    error.value = true;
  } finally {
    done.value = true;
  }
};

const reloadUploader = () => {
  window.location.reload();
};
</script>

<style lang="scss">
// @import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Josefin+Sans:wght@300;400;500;700&display=swap');

:root {
  --green: rgba(178, 190, 181, 1);
  --grey: rgba(26, 29, 26, 1);
  --black: rgba(3, 18, 14, 1);
  --ivory: rgba(255, 255, 255, 1);
  --white: rgba(255, 255, 255, 1);
  --grey--50: rgba(26, 29, 26, 0.5);
  --grey--light: rgba(241,241,241,1);
}

@font-face {
  font-family: 'Abril Fatface';
  font-style: normal;
  font-weight: 400;
  src: url('@/assets/fonts/abril.woff2') format('woff2');
}

@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 300;
  src: url('@/assets/fonts/josefin.woff2') format('woff2');
}

@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 400;
  src: url('@/assets/fonts/josefin.woff2') format('woff2');
}

@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 500;
  src: url('@/assets/fonts/josefin.woff2') format('woff2');
}

@font-face {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 700;
  src: url('@/assets/fonts/josefin.woff2') format('woff2');
}

*, *::before, *::after {
  box-sizing: border-box;
}

html, body {
  font-family: "Josefin Sans", sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  margin: 0;
  padding: 0;
}

#app {
  padding: 120px 30px;
}

.headline {
  font-family: "Abril Fatface", cursive;
  font-size: 2.5rem;
  margin: 0 0 2.5rem 0;
  color: var(--green);
  text-align: center;
}

.paragraph {
  font-size: 1.25rem;
  margin: 0 0 2.5rem 0;
  color: var(--grey);
  text-align: center;
}

.dropzone {
  position: relative;
  width: 100%;
  height: 35vh;

  .dropzone__input {
    position: relative;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 1;
    cursor: pointer;
  }

  .dropzone__overlay {
    display: grid;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .dropzone__text {
      text-align: center;
    }

    &:after {
      content: "";
      position: absolute;
      top: -3px;
      bottom: -3px;
      left: -3px;
      right: -3px;
      border: 5px dashed var(--green);
    }
  }
}

.submit,.reload {
  appearance: none;
  display: block;
  width: 100%;
  margin: 45px auto 0 auto;
  padding: 10px 20px;
  color: var(--green);
  cursor: pointer;
  border: 2px solid var(--green);
  background-color: transparent;
  transition: color 350ms ease, background-color 350ms ease;
  &:hover {
    color: var(--ivory);
    background-color: var(--green);
  }
  &:disabled {
    color: var(--ivory);
    background-color: var(--green);
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.filelist {
  margin: 45px 0 0 0;
  padding: 0;
  .filelist__item {
    display: grid;
    align-items: center;
    gap: 30px;
    grid-template-columns: 2fr 3fr;
    .filelist__filename {}
    .filelist__progress {
      display: grid;
      align-items: center;
      gap: 10px;
      grid-template-columns: 2fr 10fr;
      .filelist__percentage {}
      .filelist__bar {
        background-color: var(--grey--light);
        .filelist__inner {
          background-color: var(--green);
          height: 20px;
        }
      }
    }
  }
}
</style>